import type { AudioFile } from '@/components/solid/BucketExplorer'
import { Icon as ArkIcon } from '@/components/solid/ui/ark/icon'
import { Button } from '@/components/solid/ui/button'
import {
    Sheet as Drawer,
    SheetContent as DrawerContent,
    SheetDescription as DrawerDescription,
    SheetFooter as DrawerFooter,
    SheetTitle as DrawerTitle,
    SheetTrigger as DrawerTrigger,
} from '@/components/solid/ui/sheet'
import { Slider, SliderFill, SliderThumb, SliderTrack } from '@/components/solid/ui/slider'
import { cn } from '@/lib/utils'
import { As } from '@kobalte/core'
import { AudioState, createAudio } from '@solid-primitives/audio'
import { Icon } from 'solid-heroicons'
import { backward, forward, pause, play, speakerWave, speakerXMark } from 'solid-heroicons/outline'
import {
    For,
    Show,
    createEffect,
    createSignal,
    type Accessor,
    type Component,
    type Setter,
} from 'solid-js'

export const AudioDrawer: Component<{
    audioFile: AudioFile
    open: Accessor<boolean>
    setOpen: Setter<boolean>
}> = (props) => {
    const [source, setSource] = createSignal(props.audioFile.url)
    const [playing, setPlaying] = createSignal(false)
    const [volume, setVolume] = createSignal(0.5)
    const [audio, { seek }] = createAudio(source, playing, volume)
    const [isScrubbing, setIsScrubbing] = createSignal(false)
    const [audioScrubbingTime, setAudioScrubbingTime] = createSignal(audio.currentTime)

    const formatTime = (time: number) =>
        new Date(time * 1000).toLocaleTimeString('en-US', { minute: '2-digit', second: '2-digit' })

    const recentTracks = new Map<string, string>()

    createEffect(() => {
        if (props.audioFile.url !== source() && !recentTracks.has(props.audioFile.key)) {
            recentTracks.set(props.audioFile.key, props.audioFile.url)
            console.log('recentTracks', recentTracks)
        }
        return source()
    }, props.audioFile.url)

    return (
        <Drawer open={props.open()} onOpenChange={(d) => props.setOpen(d)}>
            <DrawerTrigger asChild>
                <As
                    component={Button}
                    id="audio-drawer-trigger"
                    variant="default"
                    size="sm"
                    type="button"
                    class="-translate-x-1/2 fixed bottom-24 left-1/2 z-50 h-10 w-10 rounded-full bg-accent-default text-accent-fg shadow-lg"
                >
                    <Icon class="h-5 w-5" path={speakerWave} />
                </As>
            </DrawerTrigger>
            <DrawerContent position="bottom" class="bg-bg-canvas p-6 pt-12">
                <div class="flex flex-col items-center justify-center gap-6">
                    <div class="w-full max-w-md space-y-4">
                        <div class="grid grid-cols-2 justify-evenly gap-8">
                            <div class="flex flex-col gap-2">
                                <DrawerTitle class="text-balance break-all">
                                    {props.audioFile.key}
                                </DrawerTitle>
                                <DrawerDescription class="text-fg-muted text-sm">
                                    {props.audioFile.file.type}
                                </DrawerDescription>
                            </div>
                            {/* Volume Slider and Mute Button */}
                            <div class="flex flex-1 items-center justify-end gap-6">
                                <Slider
                                    minValue={0}
                                    maxValue={1}
                                    step={0.1}
                                    onChange={(value) => setVolume(value[0])}
                                    value={[volume()]}
                                    class="cursor-pointer"
                                >
                                    <SliderTrack>
                                        <SliderFill />
                                        <SliderThumb />
                                    </SliderTrack>
                                </Slider>
                                <Button
                                    type="button"
                                    size="icon"
                                    disabled={audio.state === AudioState.ERROR}
                                    onClick={() => setVolume(volume() > 0 ? 0 : 0.5)}
                                    class="rounded-full p-2"
                                    variant="ghost"
                                >
                                    <ArkIcon>
                                        <Icon path={volume() > 0 ? speakerWave : speakerXMark} />
                                    </ArkIcon>
                                </Button>
                            </div>
                        </div>
                        {/* Rewind Play FastForward */}
                        <div class="flex items-center justify-center gap-6">
                            <ArkIcon>
                                <Icon path={backward} />
                            </ArkIcon>
                            <Button
                                type="button"
                                size="icon"
                                disabled={audio.state === AudioState.ERROR}
                                onClick={() => setPlaying(audio.state !== AudioState.PLAYING)}
                                class="rounded-full p-2"
                                variant="ghost"
                            >
                                <ArkIcon>
                                    <Icon
                                        path={audio.state === AudioState.PLAYING ? pause : play}
                                    />
                                </ArkIcon>
                            </Button>
                            <ArkIcon>
                                <Icon path={forward} />
                            </ArkIcon>
                        </div>
                        <div class="grid gap-4">
                            <div class="text-center">
                                <Show
                                    fallback="Loading..."
                                    when={audio.state !== AudioState.LOADING}
                                >
                                    {formatTime(
                                        !isScrubbing() ? audio.currentTime : audioScrubbingTime(),
                                    )}{' '}
                                    / {formatTime(audio.duration)}
                                </Show>
                            </div>
                            <Slider
                                minValue={0}
                                maxValue={audio.duration}
                                onChange={([time]) => {
                                    if (!time) return
                                    setAudioScrubbingTime(time)
                                    setIsScrubbing(true)
                                    seek(time)
                                }}
                                value={[audioScrubbingTime()]}
                                class="cursor-pointer"
                            >
                                <SliderTrack>
                                    <SliderFill />
                                    <SliderThumb />
                                </SliderTrack>
                            </Slider>
                        </div>
                    </div>
                </div>
                <DrawerFooter class="flex flex-col justify-start">
                    <For each={Array.from(recentTracks.entries())}>
                        {([label, url]) => (
                            <Button
                                type="button"
                                variant="link"
                                onClick={() => {
                                    setSource(url)
                                }}
                                class={cn(
                                    'cursor-pointer border-none bg-transparent px-4 py-3 transition',
                                    {
                                        'text-white hover:text-gray-900': url !== source(),
                                        'text-accent-fg': url === source(),
                                    },
                                )}
                            >
                                {label}
                            </Button>
                        )}
                    </For>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}
