import { Button } from '@/components/solid/ui/ark/button'
import * as Dialog from '@/components/solid/ui/ark/dialog'
import { Icon } from 'solid-heroicons'
import { xMark } from 'solid-heroicons/outline'
import { Show, createEffect, type Component } from 'solid-js'
import { Portal } from 'solid-js/web'

export const ConfirmDeleteModal: Component<{
    itemsToDelete: { keys: string[]; current: string }
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    deleteObjectFn: (key: string[]) => Promise<any>
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}> = (props) => {
    let focusElement: HTMLButtonElement

    createEffect(() => {
        console.log('isOpen', props.isOpen)
    })

    const handleDelete = async () => {
        await props.deleteObjectFn(
            props.itemsToDelete.current ? [props.itemsToDelete.current] : props.itemsToDelete.keys,
        )
        props.setIsOpen(false)
    }

    return (
        <>
            <Dialog.Root
                modal
                initialFocusEl={() => focusElement}
                open={props.isOpen}
                onOpenChange={() => props.setIsOpen(false)}
            >
                <Portal>
                    <Dialog.Backdrop />
                    <Dialog.Positioner>
                        <Dialog.Content>
                            <div class="flex max-w-sm flex-col gap-4 p-4 sm:max-w-md">
                                <Dialog.Title>Are you sure?</Dialog.Title>
                                <Show
                                    fallback={
                                        <Dialog.Description>
                                            This will delete{' '}
                                            <span class="font-medium">{props.itemsToDelete.current} </span>
                                            permanently from storage.
                                        </Dialog.Description>
                                    }
                                    when={props.itemsToDelete.keys.length > 1}
                                >
                                    <Dialog.Description>
                                        This will delete{' '}
                                        <span class="font-medium">{props.itemsToDelete.keys.length} </span> items from
                                        your bucket
                                    </Dialog.Description>
                                </Show>
                                <Dialog.CloseTrigger>
                                    <Button
                                        variant="ghost"
                                        type="button"
                                        size="icon"
                                        class="absolute top-0 right-0 cursor-pointer"
                                        onClick={() => props.setIsOpen(false)}
                                    >
                                        <Icon path={xMark} class="w-4 text-muted-foreground" />
                                    </Button>
                                </Dialog.CloseTrigger>
                                <div class="grid grid-cols-2 justify-center gap-4">
                                    <Dialog.CloseTrigger asChild>
                                        <Button
                                            ref={(el) => {
                                                focusElement = el
                                            }}
                                            onClick={() => props.setIsOpen(false)}
                                            variant="subtle"
                                            type="button"
                                            class="w-full"
                                        >
                                            Cancel
                                        </Button>
                                    </Dialog.CloseTrigger>

                                    <Dialog.Trigger asChild>
                                        <Button
                                            variant="destructive"
                                            type="button"
                                            class="w-full"
                                            onClick={handleDelete}
                                        >
                                            Confirm
                                        </Button>
                                    </Dialog.Trigger>
                                </div>
                            </div>
                        </Dialog.Content>
                    </Dialog.Positioner>
                </Portal>
            </Dialog.Root>
        </>
    )
}
