import { splitProps, type Component, type ComponentProps } from 'solid-js'

import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
    'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-bg-default text-fg-default hover:bg-bg-default/90',
                destructive: 'bg-red-800 text-white hover:bg-red-800/90',
                outline: 'border-border-outline hover:bg-bg-canvas bg-bg-canvas hover:text-accent-fg border',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-bg-subtle hover:text-accent-fg/80',
                link: 'text-primary underline-offset-4 hover:underline',
                icon: 'bg-transparent text-fg-default hover:bg-primary/90 hover:text-primary-foreground/90 border-border-outline',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                lg: 'h-11 rounded-md px-8',
                icon: 'h-10 w-10 rounded-md p-2',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
)

export interface ButtonProps extends ComponentProps<'button'>, VariantProps<typeof buttonVariants> {}

const Button: Component<ButtonProps> = (props) => {
    const [, rest] = splitProps(props, ['variant', 'size', 'class', 'type'])
    return (
        <button
            type={props.type ?? 'button'}
            class={cn(buttonVariants({ variant: props.variant, size: props.size }), props.class)}
            {...rest}
        />
    )
}

export { Button, buttonVariants }
