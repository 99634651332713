import type { AudioBucketMetadata } from '@/api/upload'
import { AudioDrawer } from '@/components/solid/AudioDrawer'
import { ConfirmDeleteModal } from '@/components/solid/Modal'
import { Checkbox } from '@/components/solid/ui/ark/checkbox'
import { Pagination } from '@/components/solid/ui/ark/pagination'
import { ChevronLeftIcon, ChevronRightIcon } from '@/components/solid/ui/icons'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/solid/ui/table'
import { Toaster, showToast } from '@/components/solid/ui/toast'
import { fetchBucket, honoClient, type BucketObject } from '@/lib/api'
import { colorLog, prettifySize } from '@/lib/utils'
import type { CheckboxCheckedChangeDetails } from '@ark-ui/solid'
import type { PageChangeDetails } from '@zag-js/pagination'
import 'solid-devtools'
import { Icon } from 'solid-heroicons'
import { trash } from 'solid-heroicons/outline'
import { Index, Show, createMemo, createResource, createSignal, type Component } from 'solid-js'
import { createStore, produce } from 'solid-js/store'

export type AudioFile = {
    key: string
    url: string
    file: File
}

type Page = {
    page: number
    objects: BucketObject[]
}

const MAX_PAGE_SIZE = 20

const R2BucketExplorer: Component = () => {
    const [bucketObjects, { mutate }] = createResource(async () => await fetchBucket())
    const [currentPage, setCurrentPage] = createStore<Page>({ page: 1, objects: [] })
    const [audioFile, setAudioFile] = createStore<AudioFile>({
        key: '',
        url: '',
        file: new File([], ''),
    })
    const [itemsToDelete, setItemsToDelete] = createStore<{ keys: string[]; current: string }>({
        keys: [],
        current: '',
    })
    const [openDeleteModal, setOpenDeleteModal] = createSignal(false)
    const [openPlayer, setOpenPlayer] = createSignal(false)

    const addItemsToDelete = (key: string) => {
        const items = new Set(itemsToDelete.keys)
        items.add(key)
        setItemsToDelete({ keys: Array.from(items), current: key })
    }
    const removeItemsToDelete = (key: string) => {
        const items = itemsToDelete.keys.filter((item) => item !== key)
        setItemsToDelete({ keys: items, current: key })
    }

    /**
     * Sorts the bucket objects by upload date. Updates when the bucket changes
     */
    const sortedObjects = createMemo(() =>
        bucketObjects()
            ? bucketObjects()!
                  .sort((a, b) => new Date(b.uploaded).getTime() - new Date(a.uploaded).getTime())
                  .map((file) => {
                      return {
                          ...file,
                          uploaded: new Date(file.uploaded).toLocaleString(),
                      }
                  })
            : [],
    )

    /**
     * Stores the objects in an array of pages, for use with the pagination component
     */
    const objectPages = createMemo(() => {
        const pages: Page[] = []
        for (let i = 0; i < sortedObjects().length; i += MAX_PAGE_SIZE) {
            const page = Math.floor(i / MAX_PAGE_SIZE) + 1
            pages.push({
                page,
                objects: sortedObjects().slice(i, i + MAX_PAGE_SIZE),
            })
        }
        return pages
    })

    const handlePageChange = async (details: PageChangeDetails) => {
        console.log('page change', details)
        setCurrentPage(
            produce((p) => {
                p.objects = objectPages()[currentPage.page - 1].objects
                p.page = currentPage.page
            }),
        )
    }

    const showToastNotification = (props: {
        title: string
        description: string
        type: 'success' | 'error'
    }) => {
        return showToast({
            description: <p>{props.description}</p>,
            title: <p>{props.title}</p>,
            variant: props.type === 'error' ? 'destructive' : 'default',
            duration: 3000,
        })
    }

    const activeAudioFile = createMemo(
        () => audioFile,
        { audioFile },
        { equals: (a, b) => a.url === b.url },
    )

    const handleFileClick = async (file: BucketObject) => {
        console.log('clicked row', file)
        if (!file.customMetadata?.fileType) return
        if (file.customMetadata.fileType.includes('audio')) {
            if (audioFile.key !== file.key) {
                setOpenPlayer(true)
            }
            await loadAudioFile(file.key)
        }
    }

    const loadAudioFile = async (key: string) => {
        console.log(colorLog(`Loading file to player -> ${key}`, 'Magenta'))
        if (navigator.storage?.estimate) {
            const { usage, quota } = await navigator.storage.estimate()
            console.log(
                `Using ${usage && prettifySize(usage)} out of ${
                    quota && prettifySize(quota)
                } bytes.`,
            )
        }
        // Use a specific cache name for your audio files
        const cacheName = 'audio-files-cache'
        const cache = await caches.open(cacheName)

        try {
            let response = await cache.match(key)
            if (!response) {
                response = (await honoClient.download[':key'].$get({
                    param: {
                        key,
                    },
                    query: {
                        length: undefined,
                    },
                })) as unknown as Response
                cache.put(key, response.clone() as Response)
            } else {
                console.log(colorLog('Using cached response', 'BrightYellow'))
            }
            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            setAudioFile(
                produce((f) => {
                    f.file = new File([blob], key)
                    f.url = url
                    f.key = key
                }),
            )
        } catch (error) {
            console.error('Error loading file', error)
        }
    }

    const handleCheckboxChange = (e: CheckboxCheckedChangeDetails, key: string) => {
        if (e.checked) {
            addItemsToDelete(key)
            console.log('added item to delete', e, itemsToDelete.keys.length)
        } else {
            removeItemsToDelete(key)
            console.log('removed item to delete', itemsToDelete.keys.length)
        }
    }

    const handleDeleteIconClick = (key: string) => {
        console.log('delete initiated for', key)
        addItemsToDelete(key)
        setOpenDeleteModal(true)
    }

    /**
     * Handles the trash can delete icon click. Deletes the selected files from the bucket then updates the bucket list
     */
    const handleDelete = async () => {
        console.log('deleting', itemsToDelete)
        const newList = await deleteObjects(itemsToDelete.keys)
        if (!newList) {
            showToastNotification({
                title: 'Error Deleting File',
                description: 'An error occurred while deleting the file',
                type: 'error',
            })
            return
        }
        if (itemsToDelete.keys.length === 1) {
            showToastNotification({
                title: 'File Deleted',
                description: `Successfully deleted ${itemsToDelete.keys[0]!}`,
                type: 'success',
            })
        } else {
            showToastNotification({
                title: 'Files Deleted',
                description: `Successfully deleted ${itemsToDelete.keys.length}`,
                type: 'success',
            })
        }
        // update the list and reset the items to delete
        mutate(newList.objects)
        setItemsToDelete({ keys: [], current: '' })
    }

    /**
     * Deletes objects from the bucket
     * @param keys - Array of keys to delete
     */
    const deleteObjects = async (keys: string[]) => {
        console.log('\x1b[95mDeleting files ->\x1b[0m', keys)
        const res = await honoClient.delete.$delete({
            json: {
                keys: keys,
            },
        })
        if (!res.ok) {
            const error = await res.json()
            console.error('Error deleting file', error)
            return
        }
        const { data, error } = await res.json()
        if (error) {
            console.error('Error deleting file', error)
            return
        }
        return data
    }

    return (
        <div class="flex flex-1 flex-col overflow-auto">
            <div class="flex items-center gap-2 px-4 py-2">
                <ChevronLeftIcon class="h-5 w-5" />
                <ChevronRightIcon class="h-5 w-5" />
                <span class="text-fg-muted text-sm">
                    {bucketObjects()?.length ?? 0} items in this bucket
                </span>
            </div>
            <Table class="flex-1">
                <TableHeader>
                    <TableRow>
                        <TableHead>
                            {/* Selects all files in the bucket */}
                            <Checkbox
                                onCheckedChange={(e) => {
                                    if (e.checked === true) {
                                        setItemsToDelete({
                                            keys: sortedObjects().map((file) => file.key),
                                            current: '',
                                        })
                                    } else {
                                        setItemsToDelete({ keys: [], current: '' })
                                    }
                                }}
                                checked={
                                    sortedObjects().length > 0 &&
                                    itemsToDelete.keys.length === sortedObjects().length
                                }
                                disabled={sortedObjects().length === 0}
                            />
                        </TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Size</TableHead>
                        <TableHead aria-sort="descending">Date Modified</TableHead>
                        <TableHead>Type</TableHead>
                    </TableRow>
                </TableHeader>
                {sortedObjects().length ? (
                    <TableBody>
                        <Index each={sortedObjects()}>
                            {(file) => (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            onCheckedChange={(e) =>
                                                handleCheckboxChange(e, file().key)
                                            }
                                            checked={itemsToDelete.keys.includes(file().key)}
                                        />
                                    </TableCell>
                                    {/* File Name */}
                                    <TableCell
                                        class="cursor-pointer font-medium hover:underline"
                                        onClick={async () => await handleFileClick(file())}
                                    >
                                        {file().key}
                                    </TableCell>
                                    {/* File Size */}
                                    <TableCell>{prettifySize(file().size)}</TableCell>
                                    {/* File Upload Date */}
                                    <TableCell>{file().uploaded.toLocaleString()}</TableCell>
                                    {/* File Metadata */}
                                    <TableCell>
                                        {(file().customMetadata as AudioBucketMetadata).fileType}
                                    </TableCell>
                                    {/* Trash Can Delete Icon */}
                                    <TableCell class="flex justify-center">
                                        <Icon
                                            path={trash}
                                            onClick={() => handleDeleteIconClick(file().key)}
                                            class="h-5 w-5 cursor-pointer text-fg-muted"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </Index>
                        <ConfirmDeleteModal
                            itemsToDelete={itemsToDelete}
                            setIsOpen={setOpenDeleteModal}
                            isOpen={openDeleteModal()}
                            deleteObjectFn={handleDelete}
                        />
                    </TableBody>
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell class="font-medium">
                                <div>No Files in this Bucket</div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
            <Pagination
                count={objectPages().length}
                position="center"
                onPageChange={handlePageChange}
                class="mt-6"
                pageSize={MAX_PAGE_SIZE}
                page={currentPage.page}
            />
            <Show when={audioFile.url && audioFile.key}>
                <AudioDrawer
                    audioFile={activeAudioFile()}
                    open={openPlayer}
                    setOpen={setOpenPlayer}
                />
            </Show>
            <Toaster />
        </div>
    )
}

export default R2BucketExplorer
