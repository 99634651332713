import { Button } from '@/components/solid/ui/ark/button'
import { Pagination as ArkPagination, type PaginationRootProps } from '@ark-ui/solid'
import { For, splitProps } from 'solid-js'
import { tv, type VariantProps } from 'tailwind-variants'

export interface PaginationProps extends PaginationRootProps, PaginationVariantProps {}

export const Pagination = (props: PaginationProps) => {
    const [variantProps, rootProps] = splitProps(props, ['class', 'position'])
    const { root, ellipsis, item, prevTrigger, nextTrigger } = styles(variantProps)

    return (
        <ArkPagination.Root class={root()} {...rootProps}>
            <ArkPagination.PrevTrigger class={prevTrigger()}>
                <Button variant="icon" aria-label="Next Page">
                    <ChevronLeftIcon />
                </Button>
            </ArkPagination.PrevTrigger>
            <ArkPagination.Context>
                {(api) => (
                    <For each={api().pages}>
                        {(page, index) =>
                            page.type === 'page' ? (
                                <ArkPagination.Item {...page} class={item()} asChild>
                                    <Button variant="ghost">{page.value}</Button>
                                </ArkPagination.Item>
                            ) : (
                                <ArkPagination.Ellipsis index={index()} class={ellipsis()}>
                                    &#8230;
                                </ArkPagination.Ellipsis>
                            )
                        }
                    </For>
                )}
            </ArkPagination.Context>
            <ArkPagination.NextTrigger class={nextTrigger()}>
                <Button variant="icon" aria-label="Next Page">
                    <ChevronRightIcon />
                </Button>
            </ArkPagination.NextTrigger>
        </ArkPagination.Root>
    )
}

type PaginationVariantProps = VariantProps<typeof styles>

const styles = tv(
    {
        base: 'pagination',
        defaultVariants: { position: 'center' },
        slots: {
            root: 'pagination__root',
            item: 'pagination__item',
            ellipsis: 'pagination__ellipsis',
            prevTrigger: 'pagination__prevTrigger',
            nextTrigger: 'pagination__nextTrigger',
        },
        variants: {
            position: {
                center: {
                    root: 'justify-center',
                },
                start: {
                    root: 'justify-start',
                },
                end: {
                    root: 'justify-end',
                },
            },
        },
        compoundVariants: [
            {
                class: {
                    root: ['mt-4'],
                },
            },
        ],
    },
    { twMerge: false },
)

const ChevronLeftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Chevron Left</title>
        <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m15 18l-6-6l6-6"
        />
    </svg>
)

const ChevronRightIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Chevron Right</title>
        <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m9 18l6-6l-6-6"
        />
    </svg>
)
